<template>
  <div
    class="explorer"
  >
    <div class="explorer-content-main">
      <pagination-buttons
        v-if="totalPages && totalPages > 1"
        :page-number="entitiesPageIndex + 1"
        :disable-prev="entitiesPageIndex === 0 || loading"
        :disable-next="isLastPage || loading"
        :total-pages="totalPages"
        @go-to-prev-page="goToPrevPage"
        @go-to-next-page="goToNextPage"
        @go-to-page="goToPage"
      />
      <loading-overlay
        v-show="entitiesStoreIsLoading"
        :overlay-opaque="true"
        :style="{'background': 'transparent'}"
      />
      <entity-table
        :entities="(entitiesPage?.entities ?? [])"
        :is-all-rows-selected="isAllRowsSelected"
        :is-loading="entitiesStoreIsLoading"
        :row-selection-data="entitySelection"
        @toggleSelectAll="$emit('toggleSelectAll')"
        @toggleSelectRow="(id) => $emit('toggleSelectRow', id)"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { allowedStates } from '@/store/helpers/storeState';
import LoadingOverlay from '@/components/general/LoadingOverlay.vue';
import EntityTable from '@/components/entities/EntityTable.vue';
import PaginationButtons from '@/components/general/PaginationButtons.vue';

export default {
  components: {
    EntityTable,
    PaginationButtons,
    LoadingOverlay,
  },
  props: {
    entitySelection: {
      type: Object,
      required: true,
    },
    isAllRowsSelected: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['toggleSelectAll', 'toggleSelectRow'],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      entitiesPage: 'entities/entitiesPage',
      entitiesPageIndex: 'entities/entitiesPageIndex',
      totalPages: 'entities/totalPages',
      storeStatus: 'entities/storeStatus',
    }),
    isLastPage() {
      return this.totalPages === 0 ? true : (this.entitiesPageIndex + 1 === this.totalPages);
    },
    entitiesStoreIsLoading() {
      return this.storeStatus === allowedStates.IS_LOADING || this.storeStatus === allowedStates.IS_BLANK;
    },
    totalEntitiesOnCurrentPage() {
      return this.entitiesPage?.entityCount;
    },
  },
  watch: {
    totalEntitiesOnCurrentPage(newVal) {
      if (newVal === 0 && this.entitiesPageIndex !== 0) {
        this.goToPrevPage();
      }
    },
  },
  methods: {
    ...mapActions({
      nextPage: 'entities/nextPage',
      prevPage: 'entities/prevPage',
      jumpToPage: 'entities/jumpToPage',
    }),
    async goToNextPage() {
      this.loading = true;
      if (!this.isLastPage) {
        await this.nextPage();
      }
      this.loading = false;
    },
    async goToPrevPage() {
      this.loading = true;
      if (!(this.entitiesPageIndex <= 0)) {
        await this.prevPage();
      }
      this.loading = false;
    },
    async goToPage(pageNumber) {
      this.loading = true;
      await this.jumpToPage(pageNumber);
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.explorer {
  position: relative;
  min-height: 80vh;
}
</style>
