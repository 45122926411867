<template>
  <table class="contained-table">
    <tr :style="{zIndex: '1', position: 'relative'}">
      <th class="table-col-0">
        <checkbox-input
          id="entitySelectAll"
          :model-value="isAllRowsSelected"
          @update:modelValue="onToggleSelectAll"
        />
      </th>
      <th>
        <span>Name</span>
        <span
          class="icon-container column-header-icon"
          :class="sortedColumnClass('name')"
          @click="onClickColumnSort('name')"
        >
          <triangle-icon
            class="icon-sort"
            size="0.75x"
          />
        </span>
      </th>
      <th class="text-centre">
        <span>AKA</span>
        <span
          class="icon-container column-header-icon"
          :class="sortedColumnClass('akaCount')"
          @click="onClickColumnSort('akaCount')"
        >
          <triangle-icon
            class="icon-sort"
            :class="orderByColumn === 'akaCount' && sortIconClass"
            size="0.75x"
          />
        </span>
      </th>
      <th class="text-centre">
        <span>Relationships</span>
        <span
          class="icon-container column-header-icon"
          :class="sortedColumnClass('relationshipCount')"
          @click="onClickColumnSort('relationshipCount')"
        >
          <triangle-icon
            class="icon-sort"
            :class="orderByColumn === 'relationshipCount' && sortIconClass"
            size="0.75x"
          />
        </span>
      </th>
      <th>
        <span>Type</span>
        <span
          class="icon-container column-header-icon"
          :class="sortedColumnClass('metricIdentifierDisplayName')"
          @click="onClickColumnSort('metricIdentifierDisplayName')"
        >
          <triangle-icon
            class="icon-sort"
            size="0.75x"
          />
        </span>
      </th>
      <th class="text-centre">
        <span>ID</span>
        <span
          class="icon-container column-header-icon"
          :class="sortedColumnClass('interDocumentEntityId')"
          @click="onClickColumnSort('interDocumentEntityId')"
        >
          <triangle-icon
            class="icon-sort"
            size="0.75x"
          />
        </span>
      </th>
      <th class="text-centre">
        <span>Last Edit</span>
        <span
          class="icon-container column-header-icon"
          :class="sortedColumnClass('timeUpdated')"
          @click="onClickColumnSort('timeUpdated')"
        >
          <triangle-icon
            class="icon-sort"
            size="0.75x"
          />
        </span>
      </th>
    </tr>
    <tr
      v-for="entity in entities"
      :key="entity.interDocumentEntityId"
    >
      <td class="table-col-0">
        <checkbox-input
          :id="`entitySelect-${entity.interDocumentEntityId}`"
          :model-value="rowSelectionData[entity.interDocumentEntityId]?.isSelected ? true : false"
          @update:modelValue="onToggleSelectRow(entity.interDocumentEntityId)"
        />
      </td>
      <td class="name-column">
        <router-link
          :to="{ name: 'entity', params: { name: entity.name }}"
          class="cell-link"
        >
          {{ entity.name }}
        </router-link>
      </td>
      <td class="text-centre aka-count-column">
        {{ entity.akaCount }}
      </td>
      <td class="text-centre relationship-count-column">
        {{ entity.relationshipCount ?? '' }}
      </td>
      <td class="type-column">
        <div
          v-if="entity.displayName.length > 0"
          class="type-pills"
        >
          <entity-type-pill
            v-for="(entityType, idx) in entity.displayName"
            :key="idx"
            :display-name="entityType"
          />
        </div>
        <span
          v-else
          class="missing-info-placeholder"
        >
          Not defined
        </span>
      </td>
      <td class="text-centre id-column">
        <span v-if="entity.clientIdentifier">{{ entity.clientIdentifier }}</span>
        <span
          v-else
          class="missing-info-placeholder"
        >No ID found</span>
      </td>
      <td class="text-centre last-edit-column">
        <span v-if="entity.timeUpdated">{{ formatDate(entity.timeUpdated) }}</span>
        <span
          v-else
          class="missing-info-placeholder"
        >-</span>
      </td>
    </tr>
  </table>
  <p
    v-if="!entities.length && !isLoading"
    class="hint"
  >
    No entities to display.
  </p>
</template>
<script>
import dayjs from 'dayjs';
import EntityTypePill from '@/components/entities/EntityTypePill/EntityTypePill.vue';
import { TriangleIcon } from '@zhuowenli/vue-feather-icons';
import { mapGetters } from 'vuex';
import CheckboxInput from '../forms/CheckboxInput.vue';

export default {
  components: {
    EntityTypePill,
    CheckboxInput,
    TriangleIcon,
  },
  props: {
    entities: {
      type: Array,
      required: true,
    },
    rowSelectionData: {
      type: Object,
      required: true,
    },
    isAllRowsSelected: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggleSelectAll', 'toggleSelectRow'],
  computed: {
    ...mapGetters({
      orderBy: 'entities/orderBy',
    }),
    orderByColumn() {
      return this.orderBy !== null ? this.orderBy.split(':')[0] : null;
    },
    orderByDirection() {
      return this.orderBy !== null ? this.orderBy.split(':')[1] : null;
    },
    sortIconClass() {
      if (this.orderByDirection === 'desc') { return 'sort-desc'; }
      if (this.orderByDirection === 'asc') { return 'sort-asc'; }
      return '';
    },
  },
  methods: {
    onClickColumnSort(columnName) {
      // Remove sort if this column is already sorted in descending direction
      if (this.orderByColumn === columnName && this.orderByDirection === 'desc') {
        this.updateOrderByQueryParam(null);
        return;
      }
      // Sort this column by descending if this column already sorted in ascending direction
      if (this.orderByColumn === columnName && this.orderByDirection === 'asc') {
        this.updateOrderByQueryParam(`${columnName}:desc`);
        return;
      }
      // Sort by ascending
      this.updateOrderByQueryParam(`${columnName}:asc`);
    },
    sortedColumnClass(columnName) {
      if (this.orderByColumn === columnName) {
        return this.sortIconClass;
      }
      return '';
    },
    updateOrderByQueryParam(orderBy) {
      this.$router.push({ query: { ...this.$route.query, orderBy } });
    },
    formatDate(datetime) {
      if (dayjs(datetime).isValid()) {
        return dayjs(datetime).format('MMM DD YYYY');
      }
      return '';
    },
    onToggleSelectAll() {
      this.$emit('toggleSelectAll');
    },
    onToggleSelectRow(rowId) {
      this.$emit('toggleSelectRow', rowId);
    },
  },
};
</script>
<style lang="scss" scoped>
.type-pills {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.contained-table {
  font-size: $txt-p-size;
  margin-bottom: 55px;
  min-width: 900px;

  .name-column, .type-column {
    width: 25%;
    min-width: 225px;
  }

  .aka-count-column, .relationship-count-column, .id-column, .last-edit-column {
    width: 12.5%;
  }

  td {
    vertical-align: middle;
  }

  th {
    white-space: nowrap;
    &.table-col-0 {
      z-index: 1;
    }

    .icon-container:hover {
      background-color: transparent;
    }
  }
}

.sort-desc {
  svg {
    fill: $bg-body-neg;
    stroke: $bg-body-neg;
  }
}

.sort-asc {
  svg {
    transform: rotate(0deg);
    fill: $bg-body-neg;
    stroke: $bg-body-neg;
  }
}
</style>
