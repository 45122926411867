<template>
  <splitpanes>
    <pane
      class="main-content-pane"
      size="70"
      :style="loadingUserSettings && {position: 'relative'}"
    >
      <div v-if="loadingUserSettings">
        <loading-overlay />
      </div>
      <div
        :style="{visibility: loadingUserSettings ? 'hidden' : 'visible'}"
        class="dashboard explorer-content-main"
      >
        <entity-table-actions
          v-if="totalSelected > 0"
          :text="`${totalSelected} ${totalSelected > 1 ? 'entities' : 'entity'} selected`"
          :user-entity-permissions="userEntityPermissions"
          :loading-delete="loadingDelete"
          @on-click-delete="onDeleteEntities"
          @unselectAll="resetRowSelection"
        />
        <div
          class="main-container"
        >
          <h1>
            Entities
          </h1>
          <entities-summary-metrics :style="{ marginTop: '34px' }" />
          <entity-actions
            :style="{'margin-left': 'auto'}"
            :hide-menu="true"
            :user-entity-permissions="userEntityPermissions"
            @add-new="setShowCreateEntityForm(true)"
          />
        </div>
        <div
          class="entities-filter-bar"
        >
          <search-bar
            placeholder="Search Entities..."
            :value="$route.query.searchBy ?? ''"
            @update-search="updateSearch"
          />
        </div>
        <hr>
        <entities-explorer
          :entity-selection="entitySelection"
          :is-all-rows-selected="isAllRowsSelected"
          @toggleSelectAll="isAllRowsSelected ? unselectAll() : selectAll()"
          @toggle-select-row="toggleSelectRow"
        />
      </div>
    </pane>
    <pane
      v-if="showCreateEntityForm"
      class="pane-details main-content-pane"
      size="30"
      style="minWidth: 450px"
    >
      <aside
        :style="{ width: '100%', zIndex: '1', position: 'relative' }"
      >
        <entity-creation-form
          @close="setShowCreateEntityForm(false)"
        />
      </aside>
    </pane>
  </splitpanes>
</template>
<script>
import { computed } from 'vue';
import { Pane, Splitpanes } from 'splitpanes';
import LoadingOverlay from '@/components/general/LoadingOverlay.vue';
import EntitiesExplorer from '@/components/entities/EntitiesExplorer.vue';
import debounce from 'lodash.debounce';
import { mapActions, mapGetters, useStore } from 'vuex';
import { successMessages, errorMessages } from '@/store/helpers/display/toastMessages';
import { useToast } from 'vue-toastification';
import SearchBar from '@/components/general/SearchBar.vue';
import EntityActions from '@/components/entities/EntityActions.vue';
import EntityCreationForm from '@/components/entities/forms/EntityCreationForm.vue';
import EntityTableActions from '@/components/entities/EntityTableActions.vue';
import 'splitpanes/dist/splitpanes.css';
import { allowedStates } from '@/store/helpers/storeState';
import EntitiesSummaryMetrics from '@/components/entities/entitiesSummaryMetrics/EntitiesSummaryMetrics.vue';
import useTable from '@/hooks/useTable';

export default {
  components: {
    EntitiesExplorer,
    SearchBar,
    EntityActions,
    EntityCreationForm,
    Pane,
    Splitpanes,
    EntityTableActions,
    LoadingOverlay,
    EntitiesSummaryMetrics,
  },
  setup() {
    const store = useStore();
    const initialiseEntityStore = (orderBy, searchBy) => store.dispatch('entities/init', {
      ...orderBy && { orderBy },
      filter: { ...searchBy && { nameContains: searchBy } },
    });
    const sortEntities = (orderBy) => store.dispatch('entities/sortEntities', { orderBy });
    const filterEntitiesBySearch = (searchBy) => store.dispatch('entities/filterEntities', { nameContains: searchBy });
    const columnNames = [
      'name',
      'akaCount',
      'relationshipCount',
      'metricIdentifierDisplayName',
      'interDocumentEntityId',
      'timeUpdated',
    ];
    const rows = computed(() => store.getters['entities/entitiesPage']?.entities ?? []);
    const rowKey = 'interDocumentEntityId';
    const {
      init,
      rowSelection,
      selectedRowIds,
      updateRowSelection,
      toggleSelectRow,
      isAllRowsSelected,
      selectAll,
      unselectAll,
      resetRowSelection,
    } = useTable(
      {
        initialiseStore: initialiseEntityStore,
        columnNames,
        sortRows: sortEntities,
        filterRowsBySearch: filterEntitiesBySearch,
        rows,
        rowKey,
      },
    );

    return {
      init,
      entitySelection: rowSelection,
      selectedEntityIds: selectedRowIds,
      updateEntitySelection: updateRowSelection,
      unselectAll,
      resetRowSelection,
      toggleSelectRow,
      selectAll,
      isAllRowsSelected,
    };
  },
  data() {
    return {
      toast: useToast(),
      showCreateEntityForm: false,
      loadingDelete: false,
    };
  },
  computed: {
    ...mapGetters({
      userEntityPermissions: 'localisation/userEntityPermissions',
      userSettingsStoreStatus: 'localisation/storeStatus',
    }),
    totalSelected() {
      return this.selectedEntityIds.length;
    },
    loadingUserSettings() {
      return this.userSettingsStoreStatus === allowedStates.IS_LOADING || this.userSettingsStoreStatus === allowedStates.IS_BLANK;
    },
  },
  async mounted() {
    this.$log.info('Entity dashboard loading...');
    this.loadEntitiesFirstPage();
  },
  methods: {
    ...mapActions({
      deleteEntities: 'entities/deleteEntities',
    }),
    loadEntitiesFirstPage() {
      this.init()
        .then(() => {
          this.$log.info('Successfully initialised first page of entities');
        })
        .catch((e) => {
          this.$log.error('Error initialising entities', e);
          this.toast.error('Error loading entities');
        });
    },
    updateSearch: debounce(function (query) { // eslint-disable-line func-names
      this.$log.debug('Debounce search triggered:', query);
      this.$router.push({ query: { ...this.$route.query, searchBy: query } });
    }, 700),
    async onDeleteEntities() {
      const entityIds = this.selectedEntityIds;
      this.$log.info('Deleting entities', entityIds);
      this.loadingDelete = true;
      this.deleteEntities(entityIds)
        .then(() => {
          this.$log.info('Entities deleted:', entityIds);
          this.toast.success(successMessages.DELETED_ENTITIES);
          this.resetRowSelection();
        })
        .catch((e) => {
          this.$log.error('Failed to delete entities:', e);
          this.toast.error(errorMessages.FAILED_DELETE_ENTITY);
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    },
    setShowCreateEntityForm(bool) {
      this.showCreateEntityForm = bool;
    },
  },
};
</script>
<style lang="scss" scoped>
.entities-filter-bar {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  padding: 0 32px;
}

.main-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
